import { createI18n } from 'vue-i18n';


const i18n = createI18n({
  legacy: false, // Desabilite o modo legacy
  fallbackLocale: 'ptBR',
  locale: 'ptBR',
  messages: {}, // Inicialmente vazio
});


export async function loadLocaleMessages(locale) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.open('GET', `/alpha/locales/${locale}.json`, true);
    
    xhr.onreadystatechange = function() {
      if (xhr.readyState === 4) {
        if (xhr.status === 200) {
          const contentType = xhr.getResponseHeader("Content-Type");
          if (contentType && contentType.includes("application/json")) {
            try {
              const messages = JSON.parse(xhr.responseText);
              resolve(messages);
            } catch (e) {
              resolve(null);
            }
          } else {
            resolve(null);
          }
        } else {
          resolve(null);  // Return null in case of error to match the original code's behavior
        }
      }
    };
    
    xhr.onerror = function() {
      resolve(null);  // Return null in case of network error to match the original code's behavior
    };

    xhr.send();
  });
}



  
  
  export async function prepareLocale(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await loadLocaleMessages(locale);


      if (messages) {
        i18n.global.setLocaleMessage(locale, messages);
      }
    }

  }

  
  export async function setLocale(locale) {
    if (!i18n.global.availableLocales.includes(locale)) {
      const messages = await loadLocaleMessages(locale);


      if (messages) {
        i18n.global.setLocaleMessage(locale, messages);
        i18n.global.locale.value = locale;

    
      }
    } else {
      i18n.global.locale.value = locale;
    }

  }

export default i18n;
